<template>
  <div class="mt-6">
    <h2 class="text-uppercase mb-3">Login to apical</h2>
    <md-divider></md-divider>
    <div class="md-layout md-gutter mt-6">
      <form @submit.prevent="onLogin" class="md-layout-item md-size-60 md-xsmall-size-100">
        <InputFieldComponent
          label="Email"
          class="my-4"
          v-model.trim="$v.form.email.$model"
          :message="hasEmailError"
          />
        <InputFieldComponent
          type="password"
          label="Password"
          class="my-4"
          v-model.trim="$v.form.password.$model"
          :message="hasPasswordError"
          
        />
        <div
          class="flex justify-between flex-wrap justify-sm-center justify-xs-center"
        >
          <md-checkbox v-model="boolean" class="md-secondary">
            <span class="font-semibold text-base">Save this password</span>
          </md-checkbox>
          <div class="flex align-center">
            <router-link
              :to="{ name: 'recoverPassword' }"
              class="text-victoria text-base"
              >forgot your password?</router-link
            >
            <md-button type="submit" class="bg-victoria text-white rounded">Login</md-button>
          </div>
        </div>
      </form>
      <div class="md-layout-item md-size-40 md-xsmall-size-100 md-xsmall-hide">
        <img src="/images/login-banner.png" alt="" />
      </div>
    </div>
    <p>{{ message }}</p>
  </div>
</template>

<script>
import { InputFieldComponent } from "@/components";
import { required } from "vuelidate/lib/validators";
import { email } from "vuelidate/lib/validators";
import { mapActions, mapMutations } from "vuex";

export default {
  components: {
    InputFieldComponent,
  },
  data: () => ({
    message: null,
    boolean: false,
    hasPasswordServerError: false,
    hasEmaildServerError: false,
    error: false,
    form: {
      email: "",
      password: "",
      type:"SUPER_ADMIN"
    },
  }),
  computed: {
    
    hasEmailError() {
      
      if ((!this.$v.form.email.required && this.$v.form.email.$dirty || !this.$v.form.email.email && this.$v.form.email.$dirty) || this.hasEmaildServerError) {
        if(this.hasEmaildServerError) {
          return this.hasEmaildServerError
        }
        return !this.$v.form.email.required && this.$v.form.email.$dirty ? 'Field is required' : !this.$v.form.email.email && this.$v.form.email.$dirty ? 'Should be in email format' : null;
      }
      return null;
    },
    hasPasswordError() {
      if (!this.$v.form.password.required && this.$v.form.password.$dirty || this.hasPasswordServerError) {
        if(this.hasPasswordServerError) {
          return this.hasPasswordServerError
        }
        return "Field is required";
      }
      return null;
    },

  },
  methods: {
    ...mapActions({
      actLogin: "user/actLogin",
      actGetUserInformation: "user/actGetUserInformation",
    }),
    ...mapMutations({
      setUser: "user/setUser",
      setShowSnackbar: "setShowSnackbar",
    }),
    onLogin() {
     
      this.$v.$touch();
      if(this.$v.$invalid) return;

      this.loading = true;
      this.message = null;
      this.actLogin(this.form)
      .then((response) => {
          this.setShowSnackbar(response.message);
            localStorage.setItem('token', response.token);
            localStorage.setItem('user_id', response.user_id);
            localStorage.setItem('user_type', 'master');
            window.location.replace('master');
      })
      .catch((error) => {
        let errors = error.response.data.errors;

        if(errors){
          if(errors.email) {
            this.hasEmaildServerError = errors.email[0];
          }
          if(errors.password) {
            this.hasPasswordServerError = errors.password[0];
          }
        }
        this.setShowSnackbar('Wrong credentials!');
      });
    }
  },
  validations: {
    form: {
      email: {required, email},
      password: {
        required,
      },
    },
  },
};
</script>
